import { useEffect, useState, useCallback } from 'react';
import Header from '../components/Header';
import OrdersTable from '../components/OrdersTable';
import '../style.css';
import getCookie from '../../functions/getCookie';

export default function AdminOrders() {
    const [orders, setOrders] = useState([]);
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const fetchOrders = useCallback(() => {
        if (loading || !hasMore) return;

        setLoading(true);
        fetch(`${process.env.REACT_APP_URL}/admin/orders/?limit=50&offset=${offset}`, {
            headers: {
                Authorization: `Bearer ${getCookie('authorization')}`
            }
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Ошибка запроса");
                }
                return response.json();
            })
            .then(data => {
                if (data.length === 0) {
                    setHasMore(false);
                } else {
                    const newOrders = data.filter(order => order.status >= 2);
                    newOrders.sort((a, b) => new Date(b.dateStart) - new Date(a.dateStart));
                    setOrders(prevOrders => [...prevOrders, ...newOrders]);
                    setOffset(prevOffset => prevOffset + 50);
                }
            })
            .catch(error => {
                console.error("Ошибка загрузки заказов:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [loading, hasMore, offset]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
            fetchOrders();
        }
    }, [fetchOrders]);

    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <>
            <Header />
            <div className="wrapper_2000">
                <div>
                    <h3 className="tablesTitle">Заказы 2000</h3>
                    <OrdersTable orders={orders} setOrders={setOrders}/>
                    {loading && <p>Загрузка...</p>}
                    {!hasMore && <p>Все заказы загружены</p>}
                </div>
            </div>
        </>
    );
}
