import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import formatPhoneNumber from '../../functions/formatPhoneNumber';
import Modal from '../../components/Modal/Modal';

const PasswordRecoveryPage = () => {
    const [phone, setPhone] = useState('');
    const [modal, setModal] = useState({
        isOpen: false,
        message: '',
    });

    const navigate = useNavigate();

    // Функция для открытия модального окна
    const openModal = (message, callback = null) => {
        setModal({
            isOpen: true,
            message,
            callback,
        });
    };

    const closeModal = useCallback(() => {
        setModal((prevState) => ({
            ...prevState,
            isOpen: false,
        }));
    }, []);

    const onChangePhone = (e) => {
        let { value } = e.target;
    
        let formattedPhoneNumber = value.replace(/[^\d+]/g, '');
    
        if (!formattedPhoneNumber.startsWith('+7')) {
          formattedPhoneNumber = `+7${formattedPhoneNumber.replace(/\D/g, '')}`;
        }
    
        formattedPhoneNumber = formattedPhoneNumber.substring(0, 12);
    
        setPhone(formattedPhoneNumber);
      };

    const handleRecovery = () => {
        const formattedPhone = formatPhoneNumber(phone);

        if (formattedPhone.length < 11) {
            openModal('Вы не заполнили поле: Телефон');
            return;
        }

        fetch(`${process.env.REACT_APP_URL}/forget-password?telephone=${formattedPhone}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((errorData) => {
                        throw new Error(errorData.detail || 'Ошибка соединения');
                    });
                }
                return response.json();
            })
            .then((data) => {
                openModal(data.message || 'Инструкции по восстановлению пароля отправлены на вашу почту.');
            })
            .catch((error) => {
                console.error(error);
                openModal(error.message || 'Произошла ошибка при восстановлении пароля.');
            });
    };

    return (
        <div className="signInCenter">
            <div className="signIn">
                <div className="signInHeader">
                    <h1 className="signInHeaderText" />
                    <span className="restorePasswaordText">восстановление пароля</span>
                </div>
                <div className="signInMain">
                    <form>
                        <input
                            className="signInFormInput phoneIcon"
                            type="text"
                            name="phone"
                            placeholder="Введите номер телефона..."
                            value={phone}
                            onChange={onChangePhone}
                        />
                    </form>

                    <button className="signInButton" onClick={handleRecovery}>
                        Восстановить
                    </button>

                    <a className="signInRegistrate" href="/lk">
                        Вернуться к входу
                    </a>
                </div>
            </div>

            <Modal isOpen={modal.isOpen} onClose={closeModal}>
                {modal.message}
            </Modal>
        </div>
    );
};

export default PasswordRecoveryPage; 