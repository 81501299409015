import React, { useEffect, useRef, useState } from 'react';
import 'maplibre-gl/dist/maplibre-gl.css';
import maplibregl from 'maplibre-gl';
import '@maplibre/maplibre-gl-geocoder/dist/maplibre-gl-geocoder.css';
import MaplibreGeocoder from '@maplibre/maplibre-gl-geocoder';
import getCookie from '../functions/getCookie';
import { DELIVERY_PRICE } from '../static/deliveryPrice';

const PickupMap = ({ onPickupSelect, type, ordersInfo, withoutPrice }) => {
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const [selectedPoint, setSelectedPoint] = useState(null);

    const pickupInfo = {
        type: 'Самовывоз',
        coord: [33.139327, 69.001269],
        address: 'г. Мурманск, ул. Промышленная, д. 18к5',
        time: 'пн-пт, 10:00-22:00',
        price: 0,
    };

    // Обновление слоя выделения на карте
    const updateSelectionLayer = (coordinates) => {
        const selectionSource = mapRef.current.getSource('selection-point');
        if (selectionSource) {
            selectionSource.setData({
                type: 'FeatureCollection',
                features: coordinates
                    ? [{
                        type: 'Feature',
                        geometry: { type: 'Point', coordinates },
                        properties: {},
                    }]
                    : [],
            });
        }
    };

    // Логика для СДЕК: запросы и обработка
    const fetchSdekPoints = () => {
        if (!getCookie('authorization')) return;

        fetch(`${process.env.REACT_APP_URL}/delivery/cdek`, {
            headers: { Authorization: `Bearer ${getCookie('authorization')}` },
        })
            .then((res) => res.json())
            .then(async (data) => {
                const geojson = data.data;

                mapRef.current.addSource('cdek-points', {
                    type: 'geojson',
                    data: geojson,
                });

                const sdekImage = await mapRef.current.loadImage('/images/sdek.png');
                const sdekImageSelected = await mapRef.current.loadImage('/images/sdek-selected.png');

                mapRef.current.addImage('sdek-icon', sdekImage.data);
                mapRef.current.addImage('sdek-icon-selected', sdekImageSelected.data);

                mapRef.current.addLayer({
                    id: 'cdek-symbol-layer',
                    type: 'symbol',
                    source: 'cdek-points',
                    layout: {
                        'icon-image': 'sdek-icon',
                        'icon-size': 0.08,
                        'icon-anchor': 'bottom',
                        'icon-allow-overlap': true,
                    },
                });

                mapRef.current.addSource('selection-point', {
                    type: 'geojson',
                    data: { type: 'FeatureCollection', features: [] },
                });

                mapRef.current.addLayer({
                    id: 'selection-layer',
                    type: 'symbol',
                    source: 'selection-point',
                    layout: {
                        'icon-image': 'sdek-icon-selected',
                        'icon-size': 0.12,
                        'icon-anchor': 'bottom',
                        'icon-allow-overlap': true,
                    },
                });

                mapRef.current.on('click', 'cdek-symbol-layer', pointClickHandler);
                mapRef.current.on('mouseenter', 'cdek-symbol-layer', () => {
                    mapRef.current.getCanvas().style.cursor = 'pointer';
                });
                mapRef.current.on('mouseleave', 'cdek-symbol-layer', () => {
                    mapRef.current.getCanvas().style.cursor = '';
                });
            })
            .catch((error) => console.error('Ошибка при загрузке СДЕК:', error));
    };

    // Обработчик клика по точке на карте
    const pointClickHandler = (e) => {
        const features = e.features;
        if (features?.length > 0) {
            const feature = features[0];
            const code = feature.properties.code;

            fetch(`${process.env.REACT_APP_URL}/delivery/cdek/${code}`, {
                headers: { Authorization: `Bearer ${getCookie('authorization')}` },
            })
                .then((res) => res.json())
                .then((data) => {
                    const { location, work_time, type } = data[0];
                    let deliveryPrice = 0;
                    deliveryPrice = DELIVERY_PRICE['СДЕК'] * ordersInfo?.weight;
                    if (deliveryPrice < DELIVERY_PRICE['СДЕК']) {
                        deliveryPrice = DELIVERY_PRICE['СДЕК'];
                    }

                    setSelectedPoint({
                        company: 'СДЕК',
                        city: location.city,
                        address: `${location.city}, ${location.address}`,
                        workTime: work_time,
                        type: type === 'PVZ' ? 'Пункт выдачи' : 'Постомат',
                        price: deliveryPrice,
                        code,
                    });
                    updateSelectionLayer(feature.geometry.coordinates);
                });
        }
    };

    useEffect(() => {
        mapRef.current = new maplibregl.Map({
            container: mapContainerRef.current,
            style: { /* Yandex */
                version: 8,
                sources: {
                    yandex: {
                        type: 'raster',
                        tiles: [
                            'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&projection=web_mercator',
                        ],
                        tileSize: 256,
                    },
                },
                layers: [{ id: 'yandex', type: 'raster', source: 'yandex' }],
            },
            center: [37.62, 55.75],
            zoom: 10,
        });

        const navControl = new maplibregl.NavigationControl({ showCompass: true });
        mapRef.current.addControl(navControl, 'top-right');

        const geolocateControl = new maplibregl.GeolocateControl({
            positionOptions: { enableHighAccuracy: true },
            fitBoundsOptions: { maxZoom: 10 },
            trackUserLocation: true,
            showUserHeading: true,
            showAccuracyCircle: false,
        });
        mapRef.current.addControl(geolocateControl, 'top-right');

        const geocoderApi = {
            forwardGeocode: async (config) => {
                const features = [];
                try {
                    const request = `https://nominatim.openstreetmap.org/search?q=${config.query}&format=geojson&polygon_geojson=1&addressdetails=1`;
                    const response = await fetch(request);
                    const geojson = await response.json();
                    for (const feature of geojson.features) {
                        const center = [
                            feature.bbox[0] + (feature.bbox[2] - feature.bbox[0]) / 2,
                            feature.bbox[1] + (feature.bbox[3] - feature.bbox[1]) / 2,
                        ];
                        const point = {
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates: center,
                            },
                            place_name: feature.properties.display_name,
                            properties: feature.properties,
                            text: feature.properties.display_name,
                            place_type: ['place'],
                            center,
                        };
                        features.push(point);
                    }
                } catch (e) {
                    console.error(`Failed to forwardGeocode with error: ${e}`);
                }

                return {
                    features,
                };
            },
        };
        mapRef.current.addControl(
            new MaplibreGeocoder(geocoderApi, {
                maplibregl,
            }),
            'top-left'
        );

        if (type === 'sdek') fetchSdekPoints();
        if (type === 'pickup') {
            setSelectedPoint(pickupInfo);
            mapRef.current.setCenter(pickupInfo.coord);
            new maplibregl.Marker({ color: '#591e4a' })
                .setLngLat(pickupInfo.coord)
                .addTo(mapRef.current);
            updateSelectionLayer(pickupInfo.coord);
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [type]);

    return (
        <div style={{ display: 'flex', width: '100%', height: '600px', position: 'relative' }}>
            <div ref={mapContainerRef} style={{ width: '100%', height: '600px' }} />
            <div style={{
                position: 'absolute',
                bottom: '0',
                left: '0',
                backgroundColor: '#ffffff',
                width: '260px',
                height: '600px',
                border: '1px solid #ccc',
                padding: '10px',
                overflowY: 'auto',
                boxSizing: 'border-box',
            }}
            >
                <div style={{ marginTop: '50px', borderTop: '1px solid #ccc' }}>
                    {selectedPoint ? (
                        <>
                            {selectedPoint.company && <p><strong>Транспортная компания: </strong>{selectedPoint.company}</p>}
                            <p><strong>Адрес:</strong> {selectedPoint.address}</p>
                            <p><strong>Время работы:</strong> {selectedPoint.time || selectedPoint.workTime}</p>
                            <p><strong>Тип:</strong> {selectedPoint.type}</p>
                            {withoutPrice ? <></> : <p><strong>Цена:</strong> {selectedPoint.price}₽</p>}
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (selectedPoint.company && selectedPoint.code) {
                                        onPickupSelect({ service: selectedPoint.company, code: selectedPoint.code, city: selectedPoint.city });
                                        return;
                                    }
                                    if (selectedPoint.type === 'Самовывоз') {
                                        onPickupSelect({ service: 'pickup', code: '', city: 'Мурманск' })
                                    }
                                }}
                                className="lkButton"
                            >
                                Выбрать
                            </button>
                        </>
                    ) : (
                        <p>Выберите пункт на карте</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PickupMap;
