import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import EXIF from 'exif-js';
import './uploadPage.css';
import { v4 as uuidv4 } from 'uuid';
import getCookie from '../../functions/getCookie';

export default function UploadPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const parameters = location.state;
    const user = useSelector((state) => state.user);
    const userId = user.id;

    const fileInputRef = useRef(null);
    const [orderUuid, setOrderUuid] = useState(undefined);
    const [images, setImages] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [progress, setProgress] = useState(0);
    const [validateFinished, setValidationFinished] = useState(false);
    const [error, setError] = useState([]);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [files, setFiles] = useState([]);
    const [uploadProgress, setUploadProgress] = useState({ uploaded: 0, total: 0 });
    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [isUploadComplete, setIsUploadComplete] = useState(false);
    const [photosObj, setPhotosObj] = useState([]);
    const [orderName, setOrderName] = useState('');
    const [isNameModalOpen, setIsNameModalOpen] = useState(false);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        const uuid = uuidv4();
        setOrderUuid(uuid);
        setIsNameModalOpen(true)

        if (getCookie('authorization')) {
            fetch(`${process.env.REACT_APP_URL}/order`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${getCookie('authorization')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "uuid": uuid,
                    "userId": userId,
                    "status": -1,
                    "price": parseInt(parameters.price.replace(/\s/g, ''), 10),
                    "photos": [],
                })
            })
        }
    }, [])


    useEffect(() => {
        if (validateFinished) {
            setError([]);
            let flag = true;
            let blockCount = 0;
            let coverCount = 0;

            images.forEach((el) => {
                const isDpiValid = el.dpiX === '300';
                const isColorSpaceValid = el.colorSpace === 'sRGB';
                const isSizeValid =
                    checkSize(el.size, parameters.blockSize) ||
                    checkSize(el.size, parameters.coverSize);
                if (checkSize(el.size, parameters.blockSize)) {
                    blockCount++;
                }
                if (checkSize(el.size, parameters.coverSize)) {
                    coverCount++;
                }
                if (!(isDpiValid && isColorSpaceValid && isSizeValid)) {
                    flag = false;
                }
            });

            if (!flag) {
                setError((prevErrors) => [...prevErrors, 'Неправильные параметры изображений']);
                setErrorModalVisible(true);
            }

            if ((blockCount + coverCount < (parameters.cnt.numberOfSpreads + 1) * parameters.cnt.numberOfBooks) && flag) {
                if (coverCount < 1) {
                    setError((prevErrors) => [...prevErrors, `Вы не загрузили ни одной обложки!`]);
                    setErrorModalVisible(true);
                    flag = false;
                }
                if (blockCount < 1) {
                    setError((prevErrors) => [...prevErrors, `Вы не загрузили ни одного разворота!`]);
                    setErrorModalVisible(true);
                    flag = false;
                }
            }
            if ((blockCount + coverCount === (parameters.cnt.numberOfSpreads + 1) * parameters.cnt.numberOfBooks) && flag) {
                if (blockCount !== (parameters.cnt.numberOfSpreads * parameters.cnt.numberOfBooks)) {
                    setError((prevErrors) => [...prevErrors, `Загружено разворотов: ${blockCount} | Ожидалось: ${(parameters.cnt.numberOfSpreads) * parameters.cnt.numberOfBooks}`]);
                    setErrorModalVisible(true);
                    flag = false;
                }
                if (coverCount !== parameters.cnt.numberOfBooks) {
                    setError((prevErrors) => [...prevErrors, `Загружено обложек: ${coverCount} | Ожидалось: ${parameters.cnt.numberOfBooks}`]);
                    setErrorModalVisible(true);
                    flag = false;
                }
            }
            if ((blockCount + coverCount > (parameters.cnt.numberOfSpreads + 1) * parameters.cnt.numberOfBooks) && flag) {
                setError((prevErrors) => [...prevErrors, `Загружено файлов: ${blockCount + coverCount} | Необходимо не более: ${(parameters.cnt.numberOfSpreads + 1) * parameters.cnt.numberOfBooks}`]);
                setErrorModalVisible(true);
                flag = false;
            }

            if (flag) {
                setUploadProgress({ uploaded: 0, total: files.length });
                setUploadModalVisible(true);

                files.forEach((file, index) => {
                    const formData = new FormData();
                    formData.append('img', file);

                    fetch(`${process.env.REACT_APP_URL}/order/upload?userId=${userId}&orderUuid=${orderUuid}`, {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${getCookie('authorization')}`
                        },
                        body: formData,
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then((data) => {
                            setPhotosObj((prevPaths) => ({
                                ...prevPaths,
                                [data.file_path]: images[index]
                            }));
                            setUploadProgress((prevProgress) => {
                                const updatedProgress = prevProgress.uploaded + 1;
                                if (updatedProgress === prevProgress.total) {
                                    setIsUploadComplete(true);
                                }
                                return { ...prevProgress, uploaded: updatedProgress };
                            });
                        })
                        .catch((error) => {
                            setUploadError('Ошибка при загрузке файла: ' + file.name);
                            setPhotosObj([]);
                            console.error('Error uploading file:', error);
                        });
                });
            }
        }
    }, [validateFinished]);

    useEffect(() => {
        if (isUploadComplete) {
            const postData = {
                // "uuid": orderUuid, //uuid заказа
                // "id": 0, //6ти значный id заказа (тут всегда пустой)
                "status": 0, // статус (тут всегда 0)
                "readyPersent": 0, // до отправки в печать всегда 0
                // "userId": userId, //id юзера
                "isMaster": false, //на данном этапе всегда false
                "isSlave": false, //на данном этапе всегда false
                "description": "", // описание заказа (над подумать как именно это делать)
                // "price": parseInt(parameters.price.replace(/\s/g, ''), 10),
                "dateStart": 0, //дата с момента отправки в печать
                "dateFinished": 0, //дата окончания
                "weight": Number((parameters.weight / 1000).toFixed(2)), //вес
                "trackNumber": "", //трек номер (пустой пока не отправлено в печать)
                "city": "", // город (запишу на выборе пункта)
                "comment": "", //комент юзера, тоже тут пока пустой
                "name": orderName, //название заказа
                "commentAdmin": "", //комент админа (пока пустой)
                "parameters": {
                    "bas": parameters.bas,
                    "cnt": parameters.cnt,
                    "for": parameters.for,
                    "pap": parameters.pap,
                    "pro": parameters.pro,
                    "tco": parameters.tco,
                    "var": parameters.var,
                    "coverSize": parameters.coverSize,
                    "blockSize": parameters.blockSize,
                },
                // "photos": [], //пока что пусто, т.к. тут уже именно готовые
                "transportCompany": "", //пусто
                "address": "", //пусто
                "readyStatus": { //все на false
                    "accept": false,
                    "blockBuild": false,
                    "blockPrint": false,
                    "coverPrint": false,
                    "coverBuild": false,
                    "insert": false,
                    "otk": false,
                    "packing": false
                }
            }

            if (getCookie('authorization')) {
                fetch(`${process.env.REACT_APP_URL}/order/?uuid=${orderUuid}`, {
                    method: 'PATCH',
                    headers: {
                        Authorization: `Bearer ${getCookie('authorization')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(postData)
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error("Ошибка запроса");
                        }
                        return response.json();
                    })
                    .then(data => {
                        navigate('/construktor', {
                            state: {
                                parameters: parameters,
                                orderUuid: orderUuid,
                                photos: photosObj,
                            }
                        });
                    })
                    .catch(error => {
                        console.error("Ошибка при обработке ответа:", error);
                    });
            }
        }
    }, [isUploadComplete]);


    const handleFileChange = async (event) => {
        setShowModal(true);
        setProgress(0);
        setImages([]);
        setFiles([]);
        setValidationFinished(false);
        const files = event.target.files;
        const fileArray = Array.from(files);
        const totalFiles = fileArray.length;

        for (let i = 0; i < totalFiles; i += 10) {
            const fileChunk = fileArray.slice(i, i + 10);
            await processFileChunk(fileChunk);
            setProgress(((i + fileChunk.length) / totalFiles) * 100);
            await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        setShowModal(false);
        setValidationFinished(true);
        setFiles(fileArray);
    };

    const processFileChunk = (fileChunk) => {
        const imagePromises = fileChunk.map((file) => {
            return new Promise((resolve, reject) => {
                if (file && file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        const img = new Image();
                        img.src = e.target.result;
                        img.onload = () => {
                            const width = img.width;
                            const height = img.height;
                            let dpiX = '0';
                            let colorSpace = '0';

                            EXIF.getData(img, function () {
                                const exifDpiX = EXIF.getTag(this, 'XResolution');
                                const exifColorSpace = EXIF.getTag(this, 'ColorSpace');
                                if (exifDpiX) {
                                    dpiX = exifDpiX.toString();
                                }
                                if (exifColorSpace) {
                                    colorSpace = exifColorSpace === 1 ? 'sRGB' : 'Uncalibrated';
                                }
                            });

                            resolve({
                                base64: img.src,
                                name: file.name,
                                size: `${width}x${height}`,
                                dpiX,
                                colorSpace,
                            });
                        };
                    };
                    reader.readAsDataURL(file);
                } else {
                    reject(new Error('File is not an image'));
                }
            });
        });

        return Promise.all(imagePromises)
            .then((results) => {
                setImages((prevImages) => [...prevImages, ...results]);
            })
            .catch((error) => {
                console.error('Error processing images:', error);
            });
    };

    const checkSize = (size, targetSize) => {
        const [width, height] = size.split('x').map(Number);
        const [targetWidth, targetHeight] = targetSize.split('x').map(Number);

        return (Math.abs(width - targetWidth) <= 2 && Math.abs(height - targetHeight) <= 2);
    };

    const TableRow = ({ el, ind }) => {
        const getDpiCheck = (dpiX) => {
            return dpiX === '300' ? '✓' : '✗';
        };

        const getColorSpaceCheck = (colorSpace) => {
            return colorSpace === 'sRGB' ? '✓' : '✗';
        };

        const isDpiValid = el.dpiX === '300';
        const isColorSpaceValid = el.colorSpace === 'sRGB';
        const isSizeValid =
            checkSize(el.size, parameters.blockSize) ||
            checkSize(el.size, parameters.coverSize);

        const getCellStyle = (isValid) => {
            return { color: isValid ? 'green' : 'red', fontWeight: 700 };
        };

        return (
            <tr style={{ backgroundColor: isDpiValid && isColorSpaceValid && isSizeValid ? '#c8e6c9' : '#ffcdd2' }}>
                <td>{ind + 1}</td>
                <td>{el.name}</td>
                <td>{parameters.blockSize}</td>
                <td>{parameters.coverSize}</td>
                <td style={getCellStyle(isSizeValid)}>{el.size}</td>
                <td style={getCellStyle(isDpiValid)}>{getDpiCheck(el.dpiX)}</td>
                <td style={getCellStyle(isColorSpaceValid)}>{getColorSpaceCheck(el.colorSpace)}</td>
            </tr>
        );
    };

    const ErrorModal = ({ errorMessage, onClose }) => {
        return (
            <div className="errorModal_overlay" onClick={onClose}>
                <div className="errorModal" onClick={(e) => e.stopPropagation()}>
                    <button className="modalCloseButton" onClick={onClose}>
                        &times;
                    </button>
                    <div className="errorModalContent">
                        <h2>Ошибка</h2>
                        {errorMessage.map((el, index) => {
                            return <p key={index}>{el}</p>
                        })}
                    </div>
                </div>
            </div>
        );
    };

    const UploadProgressModal = ({ progress, total, error }) => {
        return (
            <div className="uploadModal_overlay">
                <div className="uploadModal" onClick={(e) => e.stopPropagation()}>
                    <div className="uploadModalContent">
                        <h2>{error ? 'Ошибка при загрузке' : 'Загрузка файлов...'}</h2>
                        {error ? (
                            <p style={{ color: 'red' }}>{error}</p>
                        ) : (
                            <p>Загружено {progress} из {total} файлов</p>
                        )}
                        <progress value={progress} max={total} />
                    </div>
                </div>
            </div>
        );
    };


    const handleCloseModal = () => setIsNameModalOpen(false);
    const handleSubmitOrder = (orderName) => setOrderName(orderName);

    const OrderNameModal = ({ isOpen, onClose, onSubmit }) => {
        const [orderName, setOrderName] = useState(null);

        const handleSubmit = () => {
            onSubmit(orderName);
            onClose();
        };

        if (!isOpen) return null;

        return (
            <div className="uploadModal_overlay" onClick={onClose}>
                <div className="uploadModal" onClick={(e) => e.stopPropagation()}>
                    <div className="uploadModalContent">
                        <h2>Введите название заказа</h2>
                        <input
                            style={{ width: '80%', fontSize: '24px' }}
                            type="text"
                            value={orderName}
                            onChange={(e) => setOrderName(e.target.value)}
                            placeholder="название..."
                        />
                        <div className="modalActions" style={{ marginTop: '20px' }}>
                            <button className="uploadCardContentButton" onClick={handleSubmit}>Подтвердить</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <div id="over"></div>
            <div className="uploadWrapper">
                <div className="uploadCard">
                    <div className="uploadCardTitle">
                        <h1>Загрузка</h1>
                    </div>
                    <div className="uploadCardContent">
                        <div className="uploadCardContentLeft">
                            <div className="uploadCardContentLeftText">
                                <h2>Важная информация!</h2>
                                <p>Для максимального ускорения и облегчения загрузки Ваших макетов на сайт, просьба перед
                                    загрузкой проверить следующие параметры файлов:</p>
                                <ol>
                                    <li>Проверить разрешение файла. Оно должно быть 300 dpi;</li>
                                    <li>Проверить цветовой профиль. Он должен быть sRGB (не CMYK);</li>
                                    <li>Размер обложки в пикселях: {parameters.coverSize}</li>
                                    <li>Размер книги в пикселях: {parameters.blockSize}</li>
                                    <li>Допускается отклонение +/- 2 пикселя.</li>
                                </ol>
                            </div>
                            <div className="uploadCardContentLeftFooter">
                                <button className="uploadCardContentButton" onClick={handleUploadButtonClick}>Загрузить</button>
                                <button className="uploadCardContentButton" onClick={() => { navigate(-1) }}>Назад</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input className="hide" type="file" accept="image/*" multiple ref={fileInputRef} onChange={handleFileChange} />

            {showModal && (
                <div className='uploadModal_overlay'>
                    <div className="uploadModal">
                        <div className="uploadModalContent">
                            <h2>Идет проверка файлов...</h2>
                            <progress value={progress} max="100" />
                        </div>
                    </div>
                </div>
            )}

            {uploadModalVisible && (
                <UploadProgressModal
                    progress={uploadProgress.uploaded}
                    total={uploadProgress.total}
                    error={uploadError}
                />
            )}

            {errorModalVisible && <ErrorModal errorMessage={error} onClose={() => setErrorModalVisible(false)} />}

            {
                isNameModalOpen && <OrderNameModal
                    isOpen={isNameModalOpen}
                    onClose={handleCloseModal}
                    onSubmit={handleSubmitOrder}
                />
            }


            {error.length !== 0 ?
                <table id="out_table" style={{ fontSize: '12px' }}>
                    <tbody id="out_table">
                        <tr style={{ backgroundColor: '#ECECEC' }}>
                            <td style={{ width: '40px' }}>№</td>
                            <td style={{ width: '220px' }}>Имя</td>
                            <td style={{ width: '100px' }}>Целевой размер разворота</td>
                            <td style={{ width: '100px' }}>Целевой размер обложки</td>
                            <td style={{ width: '100px' }}>Размер загруженного файла</td>
                            <td style={{ width: '100px' }}>Разрешение(300 DPI)</td>
                            <td style={{ width: '70px' }}>Цветовой профиль</td>
                        </tr>
                        {images.map((el, ind) => (
                            <TableRow el={el} ind={ind} key={ind} />
                        ))}
                    </tbody>
                </table>
                :
                <></>}
        </>
    );
}
