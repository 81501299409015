import { useMemo } from 'react'
import formatPrice from '../../functions/formatPrice'
import './lkUserTable.css'
import moment from 'moment'

export default function LkUserTable({ filters, orders, selectedOrders, setSelectedOrders }) {

    function getRowColor(status) {
        let color = '#ffffff'
        if (status === 0) { color = '#ffffff' }
        if (status === 1) { color = '#d9d9d9' }
        if (status === 2) { color = '#feffe7' }
        if (status === 3) { color = '#e2efd9' }

        return { backgroundColor: `${color}` }
    }

    function getStatusTextStyle(isMaster, isSlave) {
        let fontWeight = '500';
        let fontSize = 14;
        let color = '#000000'
        if (isMaster) { fontWeight = 700; fontSize = 18; color = '#0057fa' }
        if (isSlave) { fontSize = 12; color = '#6198ff' }
        return { width: '105px', fontSize: fontSize, fontWeight: `${fontWeight}`, color: `${color}` }
    }

    function getConnectedContent(el) {
        if (el.isMaster) {
            return el.slave.length
        }
        if (el.isSlave) {
            return <div className="linkedIcon"></div>
        }
        return ''
    }

    function handleButtonClick(id) {
        const order = orders.find(order => order.uuid === id);

        if (!order) return;

        let relatedIds = [];

        if (order.isMaster) {
            relatedIds = [order.uuid, ...order.slave];
        } else if (order.isSlave) {
            const masterOrder = orders.find(o => o.uuid === order.master);
            if (masterOrder) {
                relatedIds = [masterOrder.uuid, ...masterOrder.slave];
            }
        } else {
            relatedIds = [order.uuid];
        }

        if (selectedOrders.includes(id)) {
            setSelectedOrders(selectedOrders.filter(orderId => !relatedIds.includes(orderId)));
        } else {
            setSelectedOrders([...new Set([...selectedOrders, ...relatedIds])]);
        }
    }

    function TableRow({ el, ind }) {
        return (

            <tr style={getRowColor(el.status)}>
                <td style={{ width: '30px' }}>{ind + 1}</td>
                {filters !== 'inWork' ? <></> :
                    <td style={{ width: '30px' }}>
                        <button className={`tableButton ${selectedOrders.includes(el.uuid) ? 'tableButton_active' : ''}`} style={getRowColor(el.status)} onClick={(e) => { handleButtonClick(el.uuid) }} />
                    </td>}
                <td style={getStatusTextStyle(el.isMaster, el.isSlave)}>{el.name}</td>
                <td style={{ width: '60px' }}>{el.readyPersent}%</td>
                <td style={{ width: '75px' }}>{el.id}</td>
                <td style={{ width: '75px' }}>{getConnectedContent(el)}</td>
                <td style={{ width: '350px' }}>{`${el.parameters.pro}, ${el.parameters.for}, ${el.parameters.pap}, ${el.parameters.bas}, ${el.parameters.tco}, ${el.parameters.var}, разворотов - ${el.parameters.cnt?.numberOfSpreads}, книг - ${el.parameters.cnt?.numberOfBooks}`}</td>
                <td style={{ width: '60px' }}>{formatPrice(el.price)}</td>
                <td style={{ width: '100px' }}>{el.dateStart === 0 ? '' : moment(el.dateStart).format('L')}</td>
                <td style={{ width: '100px' }}>{el.dateFinished === 0 ? '' : moment(el.dateFinished).format('L')}</td>
                <td style={{ width: '45px' }}>{el.weight === 0 ? '' : `${el.weight}кг`}</td>
                <td style={{ width: '110px' }}>{el.trackNumber}</td>
                <td style={{ width: '160px' }}>{el.comment}</td>
            </tr>
        )
    }
    return (
        <div className="lkTableWrapper">
            <table className="lkTable">
                <tbody>
                    <tr>
                        <td style={{ width: '30px' }}>№</td>
                        {filters !== 'inWork' ? <></> : <td style={{ width: '30px' }} className="tableButton tableButton_active"></td>}
                        <td style={{ width: '105px' }}>Название</td>
                        <td style={{ width: '60px' }}>Готовность</td>
                        <td style={{ width: '75px' }}>Номер заказа</td>
                        <td style={{ width: '75px' }}>Связан с заказами</td>
                        <td style={{ width: '350px' }}>Описание</td>
                        <td style={{ width: '60px' }}>Цена</td>
                        <td style={{ width: '100px' }}>Дата отправки в печать</td>
                        <td style={{ width: '100px' }}>Дата выполнения</td>
                        <td style={{ width: '45px' }}>Вес</td>
                        <td style={{ width: '110px' }}>Номер отправления</td>
                        <td style={{ width: '160px' }}>Коммент</td>
                    </tr>
                    {orders.length > 0
                        ?
                        orders.map((el, ind) => { return <TableRow el={el} ind={ind} key={el.uuid} /> })
                        :
                        <></>
                    }
                </tbody>
            </table>
        </div>
    )
}