import './loginPage.css'
import getCookie from '../../functions/getCookie'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react';
import formatPhoneNumber from '../../functions/formatPhoneNumber';
import jsonToUrlEncoded from '../../functions/jsonToUrlEncoded';
import setCookie from '../../functions/setCookie';
import Modal from '../../components/Modal/Modal';

export default function LoginPage() {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const [modal, setModal] = useState({
        isOpen: false,
        message: ''
    });

    const openModal = (message, callback = null) => {
        setModal({
            isOpen: true,
            message,
            callback
        });
    };

    // Функция для закрытия модального окна
    const closeModal = useCallback(() => {
        setModal(prevState => ({
            ...prevState,
            isOpen: false
        }));
    }, []);


    useEffect(() => {
        if (getCookie('authorization')) {
            navigate('/lk')
        }
    }, [])

    function onChangePhone(e) {
        let { value } = e.target;
    
        let formattedPhoneNumber = value.replace(/[^\d+]/g, '');
    
        if (!formattedPhoneNumber.startsWith('+7')) {
          formattedPhoneNumber = `+7${formattedPhoneNumber.replace(/\D/g, '')}`;
        }
    
        formattedPhoneNumber = formattedPhoneNumber.substring(0, 12);

        setLogin(formattedPhoneNumber)
    }

    function onChangePassword(e) {
        setPassword(e.currentTarget.value)
    }

    function handleClick() {
        const postData = {
            username: formatPhoneNumber(login),
            password: password
        };

        if (postData.username.length < 11) { openModal('Вы не заполнили поле: Телефон'); return };
        if (postData.password === '') { openModal('Вы не заполнили поле: Пароль'); return };

        fetch(`${process.env.REACT_APP_URL}/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: jsonToUrlEncoded(postData)
        })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(errorData => {
                        throw new Error(errorData.detail || 'Ошибка соединения');
                    });
                }
                return response.json();
            })
            .then(data => {
                setCookie('authorization', data.access_token, 3)
                navigate(0)
            })
            .catch(error => {
                console.error(error);
                openModal(`${error.message}`);
            });
    }


    return (
        <div className="signInCenter">
            <div className="signIn">
                <div className="signInHeader">
                    <h1 className="signInHeaderText">Вход</h1>
                </div>
                <div className="signInMain">
                    <form>
                        <input className="signInFormInput phoneIcon" type="text" name="login" id="login" placeholder="введите номер телефона..." value={login} onChange={onChangePhone} />
                        <input className="signInFormInput passwordIcon" type="password" name="password" id="" placeholder="пароль..." value={password} onChange={onChangePassword} />
                    </form>

                    <div className="signInPasswordWindow">
                        <label className="signInRemeberPasswordGroup">
                            <input type="checkbox" className="signInRemeberPassword" />
                            <span className="signInRemeberPasswordText">Запомнить пароль?</span>
                        </label>
                        <a className="signInForgotPassword" href="/password-recovery">Забыли пароль?</a>
                    </div>

                    <button className="signInButton" onClick={handleClick}>
                        Войти
                    </button>
                    <a className="signInRegistrate" href="/registration">Зарегистрироваться</a>
                </div>
            </div>

            <Modal isOpen={modal.isOpen} onClose={closeModal}>
                {modal.message}
            </Modal>
        </div>
    )
}