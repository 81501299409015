import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './registrationPage.css';
import formatPhoneNumber from '../../functions/formatPhoneNumber';
import Modal from '../../components/Modal/Modal'; // Импортируем компонент модального окна

export default function RegistrationPage() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        surname: '',
        name: '',
        secondname: '',
        telephone: '',
        mail: '',
        password: '',
        c_password: ''
    });

    const [modal, setModal] = useState({
        isOpen: false,
        message: ''
    });

    // Функция для открытия модального окна
    const openModal = (message, callback = null) => {
        setModal({
            isOpen: true,
            message,
            callback
        });
    };

    // Функция для закрытия модального окна
    const closeModal = useCallback(() => {
        setModal(prevState => ({
            ...prevState,
            isOpen: false
        }));
    }, []);

    useEffect(() => {
        if (!modal.isOpen && modal.callback) {
            modal.callback(); // Вызываем колбэк только после закрытия модального окна
            setModal(prevState => ({
                ...prevState,
                callback: null // Очищаем колбэк после вызова
            }));
        }
    }, [modal.isOpen, modal.callback]);

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function validatePasswordsMatch(password, confirmPassword) {
        return password === confirmPassword;
    }

    function onChangePhone(e) {
        let { name, value } = e.target;
    
        let formattedPhoneNumber = value.replace(/[^\d+]/g, '');
    
        if (!formattedPhoneNumber.startsWith('+7')) {
          formattedPhoneNumber = `+7${formattedPhoneNumber.replace(/\D/g, '')}`;
        }
    
        formattedPhoneNumber = formattedPhoneNumber.substring(0, 12);

        setFormData(prevState => ({
            ...prevState,
            [name]: formattedPhoneNumber
        }));
    }

    function onChange(e) {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function onSumbit(e) {
        e.preventDefault();

        const postData = {
            first_name: formData.surname,
            name: formData.name,
            second_name: formData.secondname,
            telephone: formatPhoneNumber(formData.telephone),
            email: validateEmail(formData.mail) ? formData.mail : false,
            hashed_password: validatePasswordsMatch(formData.password, formData.c_password) ? formData.password : false,
        };

        if (postData.first_name === '') { openModal('Вы не заполнили поле: Фамилия'); return };
        if (postData.name === '') { openModal('Вы не заполнили поле: Имя'); return };
        if (postData.second_name === '') { openModal('Вы не заполнили поле: Отчество'); return };
        if (postData.telephone === '') { openModal('Вы не заполнили поле: Телефон'); return };
        if (postData.telephone.length < 11) { openModal('Ошибка в номере телефона'); return };
        if (postData.email === '') { openModal('Вы не заполнили поле: email'); return };
        if (postData.email === false) { openModal('Ошибка в email'); return };
        if (postData.hashed_password === '') { openModal('Вы не заполнили поле: Пароль'); return };
        if (postData.hashed_password === false) { openModal('Пароли не совпадают'); return };

        if (document.getElementById('pepe').checked) {
            fetch(`${process.env.REACT_APP_URL}/registration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(postData)
            })
                .then(response => {
                    if (!response.ok) {
                        return response.json().then(errorData => {
                            throw new Error(errorData.detail || 'Ошибка соединения');
                        });
                    }
                    return response.json();
                })
                .then(data => {
                    openModal(
                        'Для завершения регистрации проверьте ваш почтовый ящик. Найдите письмо от нас и нажмите на кнопку подтверждения, чтобы активировать ваш email. Без подтверждения почты часть функционала сайта будет недоступна. Если письма нет, проверьте папку «Спам».',
                        () => navigate('/lk')
                    );
                })
                .catch(error => {
                    console.error(error);
                    openModal(`Произошла ошибка при регистрации: ${error.message}`);
                });
        } else {
            openModal('Необходимо согласиться с пользовательским соглашением!');
        }
    }

    return (
        <div className="registrateCenter">
            <div className="registrate">
                <h1 className="registrateHeaderText">Регистрация</h1>
                <div className="registrateContent">
                    <form>
                        <input className="registrateFormInput" name="surname" id="surname" placeholder="Фамилия*" value={formData.surname} onChange={onChange} />
                        <input className="registrateFormInput" name="name" id="name" placeholder="Имя*" value={formData.name} onChange={onChange} />
                        <input className="registrateFormInput" name="secondname" id="second_name" placeholder="Отчество*" value={formData.secondname} onChange={onChange} />
                        <input className="registrateFormInput" name="telephone" id="telephone" placeholder="Моб. телефон*" value={formData.telephone} onChange={onChangePhone} />
                        <input className="registrateFormInput" name="mail" id="mail" placeholder="Эл. почта*" value={formData.mail} onChange={onChange} />
                        <input className="registrateFormInput" type="password" name="password" id="password" value={formData.password} onChange={onChange} placeholder="Пароль*" />
                        <input className="registrateFormInput" type="password" name="c_password" id="c_password" value={formData.c_password} onChange={onChange} placeholder="Подтвердите пароль*" />
                        <p className="registrateFormText">*-поля, обязательные для заполнения</p>
                    </form>
                    <button className="registrateButton" onClick={onSumbit}>
                        Зарегестрироваться
                    </button>
                    <div className="registrateFooter">
                        <input type="checkbox" id="pepe" className="registrateFooterInput" />
                        <label htmlFor="pepe"></label>
                        <span className="registrateFooterText">Продолжая использовать данный сайт и нажимая на кнопку «Стать клиентом», я подтверждаю, что согласен на обработку моих персональных данных на изложенных в Согласии на обработку персональных данных условиях и я ознакомлен с Положением по обработке персональных данных, Соглашением по использованию сервиса, Условиями оплаты и отказа от услуги.</span>
                    </div>
                </div>
            </div>

            <Modal isOpen={modal.isOpen} onClose={closeModal}>
                {modal.message}
            </Modal>
        </div>
    );
}