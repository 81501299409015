import React from 'react';
import './modal.css';

function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                <div className="modal-body">
                    <button className="modal_close_button" onClick={onClose}>
                        <span className="modal_close_icon">&times;</span>
                    </button>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default Modal;